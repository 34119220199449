import {
  Button,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Autocomplete,
  TabContext,
  TabList,
  TabPanel,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Lottie from "react-lottie";
import { useHistory, useParams } from "react-router-dom";
import Page from "src/components/Page";
import DateRangePicker from "src/components/TimePicker/DateRangePicker";
import { getDeviceTelemDispCfgs } from "src/repos/devices";
import { getDeviceHumanKeysAndCalculatedFields } from "src/repos/devices/views";
import { getTimeseries } from "src/repos/timeseries";
import { getJobs } from "src/repos/jobs";
import palette from "src/theme/palette";
import { calcGranularity } from "src/utils/analytics.ts";
import { useQueryParam } from "src/utils/hooks";
import { isAllowed } from "src/utils/tier_permissions";
import Chart2 from "./Chart2";
import chartData from "./chart_data.json";
import loadingData from "./loading.json";
import { useStyles } from "./Styles";
import { regressionNames } from "src/utils/regressionLines";
import XYPlot from "./XYPlot";
import { Trans, useTranslation } from "react-i18next";
import { isSuperUser, isOEMSuperUser } from "src/utils/session";

function Analytics({ className, machine: device, ...rest }) {
  const MULTI_PLOT_MODE = "0";
  const XY_PLOT_MODE = "1";

  const { measure } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const initialDatePast = moment().subtract(4, "hours");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  //const [humanKeyToLabel, setHumanKeyToLabel] = useState({});
  const [humanKeyToCfg, setHumanKeyToCfg] = useState({});
  const [cfgs, setCfgs] = useState({});
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [dataSets, setDataSets] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useQueryParam(
    "timezone",
    "local"
  );
  const hasUnlimitedReports = isAllowed(
    "unlimitedReport",
    device?.service_tier
  );
  const metricLimit = hasUnlimitedReports ? 15 : 5;

  const { t } = useTranslation(["glossary", "common"]);

  // NOTE: useQueryParam will serialize value as json when assembling into
  //       query string, and deserialize as JSON. "getter" and "setter" below
  //       are for the purpose of casting values to and from moment objects.
  const [selectedDate, setSelectedDate] = useQueryParam(
    "date_range",
    [initialDatePast, moment()],
    // getter
    ([start_date, end_date]) => {
      if (selectedTimezone === "local") {
        return [moment(start_date), moment(end_date)];
      }
      return [
        moment.tz(start_date, device.facility_timezone),
        moment.tz(end_date, device.facility_timezone),
      ];
    },
    // setter
    ([start_date, end_date]) => [start_date.valueOf(), end_date.valueOf()]
  );

  const [selectedStartDate, selectedEndDate] = selectedDate;
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useQueryParam("metrics", []);
  const [selectedXYMetrics, setSelectedXYMetrics] = useQueryParam(
    "xy-metrics",
    []
  );

  const [regressionLines, setRegressionLines] = useQueryParam(
    "regressions",
    []
  );
  const [XYPlotTabIndex, setXYPlotTabIndex] = useState("0");

  const [chartMode, setChartMode] = useQueryParam("type", MULTI_PLOT_MODE);

  const [datapoints, setDatapoints] = useQueryParam("datapoints", 300);

  const toggleRegression = (regressionType) => {
    if (regressionLines.includes(regressionType)) {
      setRegressionLines(
        _.reject(regressionLines, (el) => el === regressionType)
      );
    } else {
      setRegressionLines([regressionType].concat(regressionLines));
    }
  };

  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    if (selectedMetrics && device?.serial_number) handleSubmitQuery();
  }, [device]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (device?.id)
      Promise.all([
        getDeviceTelemDispCfgs(device.id)
          .then((r) => r.json())
          .then(({ data }) => data),
        getDeviceHumanKeysAndCalculatedFields(device.id).then((data) => data),
      ]).then(([cfgs, data]) => {
        setCfgs(cfgs);
        // set human_key to label mapping
        const humanKeyToCfg_ = cfgs.reduce((acc, cfg) => {
          acc[cfg.human_key] = cfg;
          return acc;
        }, {});
        setHumanKeyToCfg(humanKeyToCfg_);
        // sort and set available metrics
        const humanKeys = [];
        const humanKeysWithTitle = [];
        _.each(data, (human_key) => {
          if (human_key && humanKeyToCfg_[human_key]) {
            humanKeysWithTitle.push(human_key);
          } else {
            humanKeys.push(human_key);
          }
        });
        setAvailableMetrics([
          ...humanKeysWithTitle.sort(),
          ...humanKeys.sort(),
        ]);
      });
  }, [device]);

  useEffect(() => {
    if (!_.isNil(measure)) {
      setSelectedMetrics([measure]);
      setAutoSubmit(true);
    }
  }, [measure]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedMetrics.length === 0) {
      if (availableMetrics.includes("machine_speed")) {
        setSelectedMetrics(["machine_speed", "msf_hr"]);
      } else if (availableMetrics.includes("DF_MAIN_N_SPEED_0_0")) {
        setSelectedMetrics(["DF_MAIN_N_SPEED_0_0", "msf_hr"]);
      }
      setAutoSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableMetrics]);

  const handleSubmitQuery = () => {
    let [selectedXYXMetric, selectedXYYMetric] = selectedXYMetrics;
    let selectedFinalMetrics =
      chartMode === XY_PLOT_MODE && selectedXYXMetric && selectedXYYMetric
        ? [selectedXYXMetric, selectedXYYMetric]
        : selectedMetrics;

    if (
      selectedFinalMetrics &&
      selectedFinalMetrics.length &&
      device?.serial_number
    ) {
      setLoading(true);
      const payload = {
        metrics: selectedFinalMetrics,
        start_time: selectedStartDate.toISOString(),
        end_time: selectedEndDate ? selectedEndDate.toISOString() : undefined,
        device_id: device.id,
        granularity: calcGranularity(
          selectedStartDate.toISOString(),
          selectedEndDate.toISOString()
        ),
        datapoints: datapoints,
      };
      const jobsPayload = {
        start_time: selectedStartDate.toISOString(),
        end_time: selectedEndDate ? selectedEndDate.toISOString() : undefined,
        device_id: device.id,
      };
      getTimeseries(payload)
        .then((r) => {
          if (r.status < 300)
            r.json().then(({ data, events, state_info }) => {
              data = {
                args: {
                  data,
                  state_info,
                  events: _.filter(events, (x) =>
                    [4, 6, null].includes(x.kiwi_class)
                  ),
                },
                chartMode: chartMode,
              };
              if (chartMode === XY_PLOT_MODE) {
                data["xAxisMetric"] = selectedXYXMetric;
                data["yAxisMetric"] = selectedXYYMetric;
              }
              setDataSets((dss) => [data, ...dss]);
            });
          else {
            setIsError(true);
            setErrorText(`Error: ${r.statusText}`);
          }
        })
        .catch((e) => {
          setIsError(true);
          setErrorText(`Error: ${e}`);
        })
        .finally(() => {
          setLoading(false);
        });
      getJobs(jobsPayload)
        .then((jobsResponse) => {
          if (jobsResponse.ok) {
            return jobsResponse.json().then((jobsData) => {
              setJobs(jobsData);
            });
          } else {
            // Handle jobs response error
          }
        })
        .catch((error) => {
          // Handle jobs API call error
        });
    }
  };

  const zoomQuery = (fromDate, toDate) => {
    let [selectedXYXMetric, selectedXYYMetric] = selectedXYMetrics;
    let selectedFinalMetrics =
      chartMode === XY_PLOT_MODE && selectedXYXMetric && selectedXYYMetric
        ? [selectedXYXMetric, selectedXYYMetric]
        : selectedMetrics;

    if (
      selectedFinalMetrics &&
      selectedFinalMetrics.length &&
      device?.serial_number
    ) {
      const payload = {
        metrics: selectedFinalMetrics,
        start_time: fromDate.toISOString(),
        end_time: toDate ? toDate.toISOString() : undefined,
        device_id: device.id,
        granularity: calcGranularity(
          fromDate.toISOString(),
          toDate.toISOString()
        ),
        datapoints: datapoints,
      };
      return getTimeseries(payload);
    }
    return new Promise();
  };

  useEffect(() => {
    if (autoSubmit) {
      handleSubmitQuery();
      setAutoSubmit(false);
    }
  }, [autoSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRangeChange = (startDate, endDate) => {
    setSelectedDate([startDate, endDate]);
  };

  const handleTabChange = (event, newTabIndex) => {
    if (newTabIndex === "1") {
      setChartMode(XY_PLOT_MODE);
    } else {
      setChartMode(MULTI_PLOT_MODE);
    }
  };

  const handleXYPlotTabChange = (event, newTabIndex) => {
    setXYPlotTabIndex(newTabIndex);
  };

  const usePreset = (event, newVal) => {
    let [xAxisMetric, yAxisMetric] = newVal.split(";");

    let tmp = selectedXYMetrics;
    tmp[0] = xAxisMetric;
    tmp[1] = yAxisMetric;

    setSelectedXYMetrics(tmp);
  };

  const handleUpdateSelectedTimezone = (event, newTimeZone) => {
    if (_.isNil(newTimeZone)) {
      newTimeZone = "local";
    }

    if (newTimeZone === "local") {
      const startDate = selectedStartDate.toDate();
      const endDate = selectedEndDate.toDate();
      setSelectedDate([startDate, endDate]);
    } else {
      const startDate = moment.tz(selectedStartDate, device.facility_timezone);
      const endDate = moment.tz(selectedEndDate, device.facility_timezone);
      setSelectedDate([startDate, endDate]);
    }

    setSelectedTimezone(newTimeZone);
  };

  const handleErrorClose = () => {
    setErrorText("");
    setIsError(false);
  };

  const datePicker = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h5"
            className={classes.header}
            style={{ marginBottom: 20 }}
          >
            {t("time_range")}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body1"
              style={{
                fontSize: 12,
                color: "#333",
                fontWeight: 600,
              }}
            >
              {t("timezone_colon")}
            </Typography>
            <ToggleButtonGroup
              style={{ marginLeft: 5 }}
              exclusive
              color="primary"
              value={selectedTimezone}
              onChange={handleUpdateSelectedTimezone}
              aria-label={t("machine_uptime_date_range")}
            >
              <ToggleButton
                classes={{ selected: classes.toggleSelected }}
                value="local"
                aria-label="bold"
                size="small"
                style={{ height: 25, marginBottom: 0 }}
              >
                <Typography>{t("local")}</Typography>
              </ToggleButton>
              <ToggleButton
                classes={{ selected: classes.toggleSelected }}
                value="facility"
                aria-label="bold"
                size="small"
                style={{ height: 25, marginBottom: 0 }}
              >
                <Typography>{t("facility")}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {selectedEndDate.isBefore(selectedStartDate) && (
          <Typography variant="h5" color="error" gutterBottom>
            {t("valid_time_range_message")}
          </Typography>
        )}

        <DateRangePicker
          onApply={(event, picker) =>
            handleRangeChange(picker.startDate, picker.endDate)
          }
          open="left"
          drops="auto"
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          isRequired={true}
          inputStartDate={selectedStartDate}
          inputEndDate={selectedEndDate}
        ></DateRangePicker>
      </>
    );
  };

  const RegressionOptions = (props) => {
    return (
      <>
        <Typography
          variant="h5"
          className={classes.header}
          style={{ marginTop: 10 }}
        >
          {t("trendlines")}
        </Typography>
        <List className={classes.root}>
          {Object.values(regressionNames).map((regressionType) => {
            return (
              <ListItem
                key={`display_${regressionType}_regression`}
                dense
                button
                onClick={() => toggleRegression(regressionType)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={regressionLines.includes(regressionType)}
                    tabIndex={-1}
                    disableRipple
                    color="secondary"
                    inputProps={{
                      "aria-labelledby": `display_${regressionType}_regression`,
                    }}
                    onChange={() => toggleRegression(regressionType)}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`display_${regressionType}_regression`}
                  primary={
                    t("display") +
                    ` ${
                      regressionType === regressionNames.THEIL_SEN
                        ? t("theil-sen")
                        : _.capitalize(t(regressionType))
                    } ` +
                    t("regression_line")
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  const presetRadioButton = (xAxisMetric, yAxisMetric, title, subtitle) => (
    <FormControlLabel
      value={`${xAxisMetric};${yAxisMetric}`}
      control={<Radio />}
      label={
        <>
          <Typography variant="h6">{title}</Typography>
          <Typography component="span" className={classes.subLabel}>
            {subtitle}
          </Typography>
        </>
      }
    />
  );

  return (
    <Page className={classes.root} title="Analytics">
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={4} style={{ alignItems: "flex-start" }}>
          <Grid item xs={12} md={3} xl={3}>
            <Card {...rest} className={clsx(classes.root, className)}>
              <CardHeader title={t("generate_chart")} />
              <Divider />
              <TabContext value={chartMode} color="secondary">
                {(isSuperUser() || isOEMSuperUser()) && (
                  <TabList
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                  >
                    <Tab label={t("time_series")} value="0" />
                    <Tab label={t("tag_xy_plot")} value="1" />
                  </TabList>
                )}
                <TabPanel value="0" id="full-width-tabpanel-0">
                  {datePicker()}
                  <Divider style={{ marginTop: 15 }} />

                  <Typography
                    variant="h5"
                    className={classes.header}
                    style={{ marginTop: 20 }}
                  >
                    {t("measures")}
                  </Typography>
                  <List className={classes.root}>
                    {!_.isEmpty(availableMetrics) && (
                      <>
                        <Autocomplete
                          multiple
                          getOptionDisabled={() =>
                            selectedMetrics.length >= metricLimit
                          }
                          limitTags={3}
                          id="multiple-limit-tags"
                          options={availableMetrics}
                          onChange={(evt, metrics) =>
                            setSelectedMetrics(metrics)
                          }
                          value={selectedMetrics}
                          getOptionLabel={(option) =>
                            humanKeyToCfg[option]
                              ? humanKeyToCfg[option].title
                              : option
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={t("select_metrics_2")}
                              placeholder={t("select_metrics_2")}
                            />
                          )}
                        />
                      </>
                    )}
                  </List>
                  <RegressionOptions />
                  <Typography
                    variant="h5"
                    className={classes.header}
                    style={{ marginBottom: 10 }}
                  >
                    {t("resolution")}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <RadioGroup
                        row
                        value={String(datapoints)}
                        onChange={(e) => setDatapoints(e.target.value)}
                      >
                        <FormControlLabel
                          value="100"
                          control={<Radio color="primary" />}
                          label={t("low")}
                        />
                        <FormControlLabel
                          value="300"
                          control={<Radio color="primary" />}
                          label={t("medium")}
                        />
                        <FormControlLabel
                          value="1000"
                          control={<Radio color="primary" />}
                          label={t("high")}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      selectedMetrics.length < 1 ||
                      loading ||
                      selectedEndDate.isBefore(selectedStartDate)
                    }
                    onClick={handleSubmitQuery}
                  >
                    {loading ? t("querying") + "..." : t("generate_chart")}
                  </Button>
                  {
                    <div style={{ marginTop: "10px" }}>
                      {hasUnlimitedReports ? (
                        <Typography variant="caption">
                          <Trans
                            i18nKey="glossary:analyze_measures_message"
                            components={{
                              1: (
                                /* eslint-disable-next-line */
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/reports/measures");
                                  }}
                                  target="blank"
                                />
                              ), // for Measures Report link
                            }}
                            values={{ metricLimit: metricLimit }}
                          />
                        </Typography>
                      ) : (
                        <Typography variant="caption">
                          <Trans
                            i18nKey="glossary:metric_limit_message"
                            components={{
                              1: (
                                /* eslint-disable-next-line */
                                <a href="https://gohelios.us" target="blank" />
                              ), // for gohelios.us link
                            }}
                            values={{ metricLimit: metricLimit }}
                          />
                        </Typography>
                      )}
                    </div>
                  }
                </TabPanel>
                <TabPanel value="1" id="full-width-tabpanel-1">
                  {datePicker()}
                  <Divider style={{ marginTop: 15 }} />
                  <TabContext value={XYPlotTabIndex}>
                    <TabList
                      onChange={handleXYPlotTabChange}
                      centered
                      indicatorColor="primary"
                      variant="fullWidth"
                    >
                      <Tab label={t("presets")} value="0" />
                      <Tab label={t("custom_tags")} value="1" />
                    </TabList>
                    <TabPanel value="0" id="full-width-xyplot-tabpanel-1">
                      <FormControl style={{ marginBottom: "20px" }}>
                        <Typography
                          variant="h5"
                          className={classes.header}
                          style={{ marginBottom: "8px" }}
                        >
                          {t("metrics")}
                        </Typography>
                        <RadioGroup
                          className={classes.root}
                          onChange={usePreset}
                        >
                          {presetRadioButton(
                            "anvil_impression",
                            "sheets_per_hour",
                            t("throughput_by_impression_setting"),
                            t("throughput/impression_setting")
                          )}
                          {presetRadioButton(
                            "anvil_impression",
                            "machine_speed",
                            t("speed_by_impression_setting"),
                            t("machine_speed/impression_setting")
                          )}
                          {presetRadioButton(
                            "machine_running",
                            "sheets_per_hour",
                            t("thoughput_when_running"),
                            t("uptime/throughput")
                          )}
                          {presetRadioButton(
                            "anvil_impression",
                            "msf_hr",
                            t("msf_by_impression_setting"),
                            t("msf/impression_setting")
                          )}
                        </RadioGroup>
                        <Divider style={{ marginTop: 15 }} />
                        <RegressionOptions />
                      </FormControl>
                    </TabPanel>
                    <TabPanel value="1" id="full-width-xyplot-tabpanel-1">
                      <List className={classes.root}>
                        {!_.isEmpty(availableMetrics) && (
                          <>
                            <Autocomplete
                              id="xy-plot-x-tag"
                              options={availableMetrics}
                              onChange={(evt, metric) => {
                                let tmp = selectedXYMetrics;
                                tmp[0] = metric;
                                setSelectedXYMetrics(tmp);
                              }}
                              value={selectedXYMetrics[0]}
                              getOptionLabel={(option) =>
                                humanKeyToCfg[option]
                                  ? humanKeyToCfg[option].title
                                  : option
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label={t("x_axis_metric")}
                                  placeholder={t("x_axis_metric")}
                                />
                              )}
                            />
                          </>
                        )}
                      </List>
                      <List className={classes.root}>
                        {!_.isEmpty(availableMetrics) && (
                          <>
                            <Autocomplete
                              id="xy-plot-y-tag"
                              options={availableMetrics}
                              onChange={(evt, metric) => {
                                let tmp = selectedXYMetrics;
                                tmp[1] = metric;
                                setSelectedXYMetrics(tmp);
                              }}
                              value={selectedXYMetrics[1]}
                              getOptionLabel={(option) =>
                                humanKeyToCfg[option]
                                  ? humanKeyToCfg[option].title
                                  : option
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label={t("y_axis_metric")}
                                  placeholder={t("y_axis_metric")}
                                />
                              )}
                            />
                          </>
                        )}
                      </List>
                      <Divider style={{ marginTop: 15 }} />
                      <RegressionOptions />
                    </TabPanel>
                  </TabContext>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !selectedXYMetrics[0] ||
                      !selectedXYMetrics[1] ||
                      loading ||
                      selectedEndDate.isBefore(selectedStartDate)
                    }
                    onClick={handleSubmitQuery}
                  >
                    {loading ? t("querying") + "..." : t("generate_chart")}
                  </Button>
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            xl={9}
            style={{ maxHeight: "80vh", overflow: "auto" }}
          >
            {!loading && !_.isEmpty(dataSets) && !_.isEmpty(cfgs) ? (
              dataSets.map((ds, i) => {
                return ds.chartMode === MULTI_PLOT_MODE ? (
                  <Card
                    key={`card-${dataSets.length - 1 - i}`}
                    style={{ marginBottom: 20 }}
                  >
                    <Chart2
                      key={dataSets.length - 1 - i}
                      classes={classes}
                      {...ds.args}
                      jobs={jobs}
                      humanKeyToCfg={humanKeyToCfg}
                      start={selectedStartDate}
                      end={selectedEndDate}
                      regressionLines={regressionLines}
                      timezone={selectedTimezone}
                      facilityTimezone={device.facility_timezone}
                      zoomQuery={zoomQuery}
                      zoomEnabled
                    />
                  </Card>
                ) : (
                  <XYPlot
                    key={dataSets.length - 1 - i}
                    classes={classes}
                    {...ds.args}
                    XAxisMetric={ds.xAxisMetric}
                    YAxisMetric={ds.yAxisMetric}
                    cfgs={cfgs}
                    regressionLines={regressionLines}
                  />
                );
              })
            ) : (
              <Card
                {...rest}
                className={clsx(classes.root, className)}
                style={{
                  minHeight: 400,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loading ? loadingData : chartData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width={200}
                  height={100}
                />
                {loading ? (
                  <Typography variant="h5">Loading</Typography>
                ) : (
                  <>
                    <Typography variant="h5">
                      {t("select_a_measure_message")}
                    </Typography>
                  </>
                )}
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={isError}
        onClose={handleErrorClose}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4>{t("query_error")}</h4>
            {errorText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleErrorClose}
            color="secondary"
            autoFocus
            style={{ color: palette.status.error.main }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

Analytics.propTypes = {
  className: PropTypes.string,
};

export default Analytics;
