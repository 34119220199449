import _ from "lodash";
import moment from "moment";

interface TelemetryObject {
  category:
    | "productivity"
    | "printing"
    | "converting"
    | "machineOverview"
    | "kiwiplan"
    | undefined;
  title: string;
  type:
    | "boolean"
    | "percentage"
    | "seconds"
    | "float"
    | "passthrough"
    | "encoded";
  encoding?: object;
  units: string;
  userUpdatable: boolean;
  false?: string;
  true?: string;
  precision?: number;
}

interface TelemetryMap {
  [name: string]: TelemetryObject;
}

const PRINT_STATUS_ENCODING = {
  16: "Printing",
  32: "Safe/Setup",
  64: "Off",
  128: "Recover",
  256: "Washing",
  512: "Extended Wash",
};

export const mappedMachineTelemetry: TelemetryMap = {
  e_stop_pressed: {
    title: "E-Stop Ready",
    type: "boolean",
    userUpdatable: true,
    true: "Ready",
    false: "No",
    units: "",
    category: "machineOverview",
  },
  machine_running: {
    title: "Machine Running",
    type: "boolean",
    userUpdatable: true,
    true: "Yes",
    false: "No",
    units: "",
    category: "machineOverview",
  },

  machine_feeding: {
    title: "Machine Feeding",
    type: "boolean",
    userUpdatable: true,
    true: "Yes",
    false: "No",
    units: "",
    category: "machineOverview",
  },
  machine_running_feeding: {
    title: "Machine Running & Feeding",
    type: "boolean",
    userUpdatable: false,
    true: "Yes",
    false: "No",
    units: "",
    category: undefined,
  },
  time_online: {
    title: "Time Online",
    type: "seconds",
    userUpdatable: false,
    units: "",
    category: "machineOverview",
  },
  uptime: {
    title: "Uptime in the last 4 hrs",
    type: "percentage",
    userUpdatable: false,
    units: "",
    category: "machineOverview",
  },
  jam_detected: {
    title: "Jam Detected",
    type: "boolean",
    userUpdatable: true,
    true: "Yes",
    false: "No",
    units: "",
    category: "machineOverview",
  },
  signal_dbm: {
    title: "Helios Cell Signal",
    userUpdatable: false,
    units: "",
    category: "machineOverview",
    type: "passthrough",
  },
  msf_hr: {
    title: "MSF",
    userUpdatable: false,
    units: "per/hr",
    category: "productivity",
    type: "passthrough",
  },
  machine_speed: {
    title: "Machine Speed",
    userUpdatable: true,
    units: "RPM",
    category: "productivity",
    type: "passthrough",
  },
  sheet_count: {
    title: "Sheet Count",
    userUpdatable: true,
    units: "sheets",
    category: "productivity",
    type: "passthrough",
  },
  sheets_per_hour: {
    title: "Throughput",
    type: "passthrough",
    userUpdatable: false,
    units: "sheets/hr",
    category: "productivity",
  },
  single_feed_on: {
    title: "Single Feed On",
    type: "boolean",
    userUpdatable: true,
    true: "Yes",
    false: "No",
    units: "",
    category: "productivity",
  },
  number_of_single_sheet_feeds: {
    title: "Num Single Sheet",
    type: "passthrough",
    userUpdatable: false,
    units: "sheets",
    category: "productivity",
  },
  side_guide_left: {
    title: "Sheet Size (Guide Left)",
    userUpdatable: true,
    units: "inches",
    type: "float",
    category: undefined,
  },
  side_guide_right: {
    title: "Sheet Size (Guide Right)",
    userUpdatable: true,
    units: "inches",
    type: "float",
    category: undefined,
  },
  backstop: {
    title: "Sheet Size (Backstop)",
    userUpdatable: true,
    units: "inches",
    type: "float",
    category: undefined,
  },
  sheet_size: {
    title: "Sheet Size",
    userUpdatable: false,
    units: "ft²",
    category: "productivity",
    precision: 1,
    type: "float",
  },
  setup_in_progress: {
    title: "Setup In Progress",
    type: "boolean",
    userUpdatable: false,
    true: "Yes",
    false: "No",
    units: "",
    category: "productivity",
  },
  print_1_status: {
    title: "Print 1 Status",
    type: "encoded",
    encoding: PRINT_STATUS_ENCODING,
    userUpdatable: false,
    units: "",
    category: "printing",
  },
  print_2_status: {
    title: "Print 2 Status",
    type: "encoded",
    encoding: PRINT_STATUS_ENCODING,
    userUpdatable: false,
    units: "",
    category: "printing",
  },
  print_3_status: {
    title: "Print 3 Status",
    type: "encoded",
    encoding: PRINT_STATUS_ENCODING,
    userUpdatable: false,
    units: "",
    category: "printing",
  },
  print_4_status: {
    title: "Print 4 Status",
    type: "encoded",
    encoding: PRINT_STATUS_ENCODING,
    userUpdatable: false,
    units: "",
    category: "printing",
  },
  wash_requested: {
    title: "Wash Requested",
    type: "boolean",
    true: "Yes",
    false: "No",
    userUpdatable: true,
    units: "",
    category: "printing",
  },
  pump_strokes_1: {
    title: "Pump Strokes print 1",
    type: "float",
    userUpdatable: true,
    units: "Per minute",
    category: "printing",
  },
  pump_strokes_2: {
    title: "Pump Strokes print 2",
    type: "float",
    userUpdatable: true,
    units: "Per minute",
    category: "printing",
  },
  pump_strokes_3: {
    title: "Pump Strokes print 3",
    type: "float",
    userUpdatable: true,
    units: "Per minute",
    category: "printing",
  },
  pump_strokes_4: {
    title: "Pump Strokes print 4",
    type: "float",
    userUpdatable: true,
    units: "Per minute",
    category: "printing",
  },
  ink_pumps_running_1: {
    title: "Ink pumps running P1",
    type: "boolean",
    userUpdatable: false,
    true: "Running",
    false: "Not Running",
    units: "",
    category: "printing",
  },
  ink_pumps_running_2: {
    title: "Ink pumps running P2",
    type: "boolean",
    userUpdatable: false,
    true: "Running",
    false: "Not Running",
    units: "",
    category: "printing",
  },
  ink_pumps_running_3: {
    title: "Ink pumps running P3",
    type: "boolean",
    userUpdatable: false,
    true: "Running",
    false: "Not Running",
    units: "",
    category: "printing",
  },
  grinder_status: {
    title: "Grinder Status",
    type: "boolean",
    userUpdatable: true,
    true: "Enabled",
    false: "Disabled",
    units: "",
    category: "converting",
  },
  compensator_current: {
    title: "Compensator Current",
    type: "float",
    userUpdatable: true,
    units: "amp",
    category: "converting",
  },
  compensation_override_value: {
    title: "Comp Override Value",
    type: "float",
    userUpdatable: true,
    units: "overrides",
    category: "converting",
  },
  anvil_impression: {
    title: "Anvil Impression Setting",
    userUpdatable: true,
    type: "float",
    units: "in",
    category: "converting",
  },
  anvil_thickness: {
    title: "Anvil Cover Thickness",
    type: "float",
    userUpdatable: true,
    units: "in",
    category: "converting",
  },
  find_covers: {
    title: "Find Covers",
    type: "boolean",
    userUpdatable: true,
    true: "Active",
    false: "Not Active",
    units: "",
    category: "converting",
  },
  drives_ready: {
    title: "Drives Ready",
    type: "boolean",
    userUpdatable: false,
    true: "Ready",
    false: "Not Ready",
    units: "",
    category: "productivity",
  },
  counter_ejector_speed_diff_percentage: {
    title: "Counter Ejector Speed Curve",
    type: "percentage",
    userUpdatable: false,
    units: "from ideal",
    category: "productivity",
  },
  jams_per_hour: {
    title: "Jams Per Hour",
    type: "float",
    userUpdatable: true,
    units: "jams",
    category: "productivity",
  },
  kiwiplan_order_number: {
    title: "Order Number",
    type: "passthrough",
    userUpdatable: false,
    units: "",
    category: "kiwiplan",
  },
  kiwiplan_board_thickness: {
    title: "Board Thickness",
    type: "float",
    userUpdatable: false,
    units: "in",
    category: "kiwiplan",
  },
  kiwiplan_die_number: {
    title: "Die Number",
    type: "passthrough",
    userUpdatable: false,
    units: "",
    category: "kiwiplan",
  },
  kiwiplan_corrugator_slit_width: {
    title: "Corrugator Slit Width",
    type: "float",
    userUpdatable: false,
    units: "in",
    category: "kiwiplan",
  },
  kiwiplan_lead_corrugator_cut_length: {
    title: "Lead Corrugator Cut Length",
    type: "float",
    userUpdatable: false,
    units: "in",
    category: "kiwiplan",
  },
  kiwiplan_flute_code: {
    title: "Flute Code",
    type: "passthrough",
    userUpdatable: false,
    units: "",
    category: "kiwiplan",
  },
};

interface TelemetryDefinition {
  device_serial_number: string;
  human_key: string;
  id: string;
  machine_key: string;
}

export const mapMachineTelemetryValues = (telemetry: TelemetryDefinition[]) => {
  return _.reduce(
    mappedMachineTelemetry,
    (acc: any, machineTelemetry: any, human_key: string) => {
      machineTelemetry.machine_key = _.chain(telemetry)
        .find({ human_key })
        .get("machine_key")
        .value();

      acc = {
        ...acc,
        [human_key]: { ...machineTelemetry, human_key },
      };
      return acc;
    },
    {}
  );
};

export const convertDeviceDataToTypes = (deviceDetail: any) => {
  return _.reduce(
    deviceDetail,
    (acc: any, value: any, humanKey: string) => {
      // Assign status to each prop coming from the BE to determine check colors in overview page.
      if (mappedMachineTelemetry[humanKey]) {
        acc[`${humanKey}_status`] = isAbnormal(deviceDetail, humanKey)
          ? "abnormal"
          : "normal";
      }

      if (mappedMachineTelemetry[humanKey]?.["type"] === "percentage") {
        acc[humanKey] = `${_.round(value * 100, 0)}%`;
      } else if (mappedMachineTelemetry[humanKey]?.["type"] === "seconds") {
        var seconds = parseInt(value);
        acc[humanKey] =
          Math.floor(moment.duration(seconds, "seconds").asHours()) +
          "hr " +
          moment.duration(seconds, "seconds").minutes() +
          "min";
      } else if (mappedMachineTelemetry[humanKey]?.["type"] === "float") {
        acc[humanKey] = _.round(
          value + Number.EPSILON,
          mappedMachineTelemetry[humanKey].precision
            ? mappedMachineTelemetry[humanKey].precision
            : 3
        );
      } else if (mappedMachineTelemetry[humanKey]?.["type"] === "encoded") {
        // @ts-ignore
        acc[humanKey] = mappedMachineTelemetry[humanKey].encoding[value];
      } else {
        if (!_.isNil(value)) {
          acc[humanKey] = value;
        } else {
          acc[humanKey] = "--";
        }
      }

      return acc;
    },
    {}
  );
};

export const isAbnormal = (deviceDetail: any, key: string) => {
  return deviceDetail[`${key}_ad_flag`];
};

interface CardInfo {
  status: "normal" | "abnormal" | "unreported";
  title: string;
  value: string | boolean | number;
  unit: string;
  metric: string;
}
interface CardCategories {
  productivityCards: CardInfo[];
  printingCards: CardInfo[];
  convertingCards: CardInfo[];
  machineOverviewCards: CardInfo[];
}

export const categorizeCards = (deviceDetail: any): CardCategories => {
  const productivityCards: string[] = [];
  const printingCards: string[] = [];
  const convertingCards: string[] = [];
  const machineOverviewCards: string[] = [];

  Object.keys(mappedMachineTelemetry).forEach((key) => {
    if (mappedMachineTelemetry[key].category === "productivity") {
      productivityCards.push(key);
    } else if (mappedMachineTelemetry[key].category === "printing") {
      printingCards.push(key);
    } else if (mappedMachineTelemetry[key].category === "converting") {
      convertingCards.push(key);
    } else if (mappedMachineTelemetry[key].category === "machineOverview") {
      machineOverviewCards.push(key);
    }
  });
  return {
    productivityCards: mapInfoToCategory(productivityCards, deviceDetail),
    printingCards: mapInfoToCategory(_.orderBy(printingCards), deviceDetail),
    convertingCards: mapInfoToCategory(convertingCards, deviceDetail),
    machineOverviewCards: mapInfoToCategory(machineOverviewCards, deviceDetail),
  };
};

export const mapInfoToCategory = (
  cards: string[],
  deviceDetail: any
): CardInfo[] =>
  _.reduce(
    cards,
    (acumulator: CardInfo[], deviceProperty: string) => {
      const mappedMachineInfo = mappedMachineTelemetry[deviceProperty];
      if (mappedMachineInfo) {
        const value = deviceDetail[deviceProperty];
        let displayVal = value;
        if (value === true) {
          displayVal = mappedMachineInfo["true"] || displayVal;
        } else if (value === false) {
          displayVal = mappedMachineInfo["false"] || displayVal;
        }
        const machineInfo = {
          status: deviceDetail[`${deviceProperty}_status`] || "unreported",
          title: mappedMachineInfo.title,
          value: _.isNil(displayVal) ? "—" : displayVal,
          unit: mappedMachineInfo.units,
          metric: deviceProperty,
        };

        acumulator.push(machineInfo);
      }

      return acumulator;
    },
    []
  );

export const segmentsMappedWithTelemetry = (metric: string) => {
  const mappedMetric = mappedMachineTelemetry[metric];
  const segmentLabel = mappedMetric ? mappedMetric.title : metric;
  let segmentIndex = 1;
  const mappedSegments = _.map(
    mappedMachineTelemetry,
    (telemetry, index, key) => {
      segmentIndex++;
      return {
        id: segmentIndex,
        label: telemetry.title,
        tag: index,
      };
    }
  );
  if (metric && !mappedMachineTelemetry[metric]) {
    return [{ id: 0, label: segmentLabel, tag: metric }].concat(mappedSegments);
  }

  return mappedSegments;
};
